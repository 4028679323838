/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Link from '../../components/Link/Link';
import {
  getItemFromLocalStorage,
  setItemToLocalStorage,
  deleteFromLocalStorage,
} from '../../helpers/local_storage_manger';
import AfterAndBefore from '../../components/AfterAndBefore';

import { useMediaQuery } from 'react-responsive';
import { event } from '../../helpers/googleanalytics';
import { trackCustom } from '../../helpers/facebookanalytics';

const Goal = ({ goal, onClick, selected }) => (
  <button
    type="button"
    onClick={onClick}
    className={`dropdown-item ${selected === goal.value ? '' : ''}`}
    style={{
      textAlign: 'end',
    }}
  >
    {selected === goal.value}
    {goal.label}
    <goal.SVGIcon />
  </button>
);

const LossSVG = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 24"
    className="mg-left-5 svg-top"
    width="24px"
  >
    <g>
      <path
        d="M20.7,6.8c-0.6-0.6-1.4-1-2.2-1h-0.8C17.4,9.3,14.5,12,11,12c-3.5,0-6.4-2.7-6.7-6.1H3.5c-0.9,0-1.7,0.4-2.2,1
                    C0.7,7.5,0.5,8.3,0.6,9.2l1.3,12.1C2,22.8,3.3,24,4.8,24h12.4c1.5,0,2.8-1.2,3-2.7l1.3-12.1C21.5,8.3,21.3,7.5,20.7,6.8z
                    M14.4,20.6H7.6c-0.5,0-0.8-0.4-0.8-0.8c0-0.5,0.4-0.8,0.8-0.8h6.9c0.5,0,0.8,0.4,0.8,0.8C15.3,20.2,14.9,20.6,14.4,20.6z
                    M15.4,16.6H6.6c-0.5,0-0.8-0.4-0.8-0.8c0-0.5,0.4-0.8,0.8-0.8h8.7c0.5,0,0.8,0.4,0.8,0.8C16.2,16.2,15.8,16.6,15.4,16.6L15.4,16.6
                    z"
        fill="#FF6D38"
      />
      <path
        d="M10.6,10.4c2.8,0,5-2.3,5-5.2c0-2.9-2.3-5.2-5-5.2c-2.8,0-5,2.3-5,5.2C5.6,8.1,7.9,10.4,10.6,10.4z M9.6,4.7
                    c0.1-0.1,0.2-0.2,0.3-0.3l2.3-1.6c0.2-0.2,0.6-0.1,0.8,0.2c0.1,0.2,0.1,0.5,0,0.6L11.4,6c-0.3,0.5-1,0.6-1.5,0.3
                    C9.4,5.9,9.3,5.2,9.6,4.7z"
        fill="#FF6D38"
      />
    </g>
  </svg>
);

const FitSVG = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 57 65"
    className="mg-left-5"
    width="25px"
  >
    <g transform="translate(5 -23)">
      <g>
        <path
          d="M32.754 14.164c.976 0 1.77-.794 1.77-1.77 0-.977-.794-1.771-1.77-1.771s-1.77.794-1.77 1.77c0 .977.794 1.77 1.77 1.77z"
          transform="matrix(-1 0 0 1 49 23)"
          fill="#FF6D38"
        />
        <path
          d="M32.754 24.787c6.834 0 12.394-5.56 12.394-12.394C45.148 5.56 39.588 0 32.754 0 25.92 0 20.361 5.56 20.361 12.393c0 6.834 5.56 12.394 12.393 12.394zm0-17.041c2.563 0 4.648 2.085 4.648 4.647 0 2.563-2.085 4.648-4.648 4.648-2.563 0-4.648-2.085-4.648-4.648 0-2.562 2.085-4.647 4.648-4.647z"
          transform="matrix(-1 0 0 1 49 23)"
          fill="#FF6D38"
        />
        <path
          d="M53.326 27.526c-.422-.296-.962-.368-1.447-.193l-6.513 2.35c-2.13-1.497-4.789-2.313-7.59-2.313-3.86 0-7.458 1.59-9.675 4.212-1.712-1.08-3.752-1.465-5.811-1.057-1.395.276-2.674.898-3.705 1.763.106-.093-.076-.843-.08-.996-.028-.825.088-1.65.293-2.448.435-1.703 1.28-3.306 2.199-4.794-2.844-2.75-4.658-6.553-4.807-10.771-.936.649-1.84 1.347-2.71 2.094-2.017 1.733-3.836 3.705-5.413 5.844-1.07 1.45-2.045 2.967-2.915 4.544C1.099 33.1.049 40.055 0 41.913c-.002.103.005.205.022.306.572 3.36 3.052 6.407 6.983 8.58C10.737 52.864 15.534 54 20.511 54c4.837 0 9.444-1.056 13.118-2.991l2.785 2.476c.289.316.704.515 1.166.515h14.838C53.29 54 54 53.293 54 52.42V28.819c0-.514-.252-.997-.674-1.292z"
          transform="matrix(-1 0 0 1 49 23)"
          fill="#FF6D38"
        />
      </g>
    </g>
  </svg>
);

const PerformanceSVG = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 80 70"
    className="mg-left-5"
    width="25px"
  >
    <g transform="translate(33 -12)">
      <g>
        <path
          d="M80.05 21.96c-.72-.9-1.803-1.437-2.88-1.437-1.081 0-1.976.358-2.884 1.079l-7.92 7.378S46.566 10.442 43.149 6.84C42.073 5.76 41.173 5.4 40.268 5.4c-.361 0-.72.181-1.082.181L23.35 9.36c-2.343.542-3.602 2.7-3.063 4.862.362 1.979 2.163 3.24 3.963 3.24.358 0 .538-.18.9-.18l13.498-3.24c.9 1.08 5.404 5.76 6.122 6.66-5.583 5.938-11.16 11.879-16.741 17.82-.177.179-.177.358-.362.358-.538.9-.9 1.802-.9 2.88l-7.018 8.821-15.84 3.06C1.574 54 .128 56.16.669 58.5c.356 1.978 2.16 3.42 4.14 3.42h.72l17.462-3.42c.9-.18 1.797-.72 2.338-1.439l7.918-9.54 12.96 6.659-8.817 14.4c-1.259 1.982-.718 4.678 1.258 5.94.72.36 1.438.54 2.338.54 1.263 0 2.705-.72 3.604-1.981l11.16-18.358c.721-.902.9-2.16.538-3.24-.356-1.26-1.076-2.163-2.163-2.7L42.431 42.84l12.235-12.957 9.185 7.738c.9.72 1.797.9 2.697.9 1.079 0 1.984-.36 2.704-1.079l10.615-9.72c1.625-1.623 1.8-4.14.182-5.763z"
          transform="translate(-33 12)"
          fill="#FF6D38"
        />
        <path
          d="M63.31 18.362c5.037 0 9.177-4.14 9.177-9.181 0-5.04-4.14-9.181-9.176-9.181-5.043 0-9.185 4.14-9.185 9.18 0 5.042 4.142 9.182 9.185 9.182z"
          transform="translate(-33 12)"
          fill="#FF6D38"
        />
      </g>
    </g>
  </svg>
);

const goalOptions = [
  { value: 0, label: 'خسارة الوزن والدهون', SVGIcon: LossSVG },
  { value: 1, label: 'زيادة حجم العضلات', SVGIcon: FitSVG },
  { value: 2, label: 'زيادة اللياقة البدنية', SVGIcon: PerformanceSVG },
];

const GoalsDropdown = ({ onGoalChange, style, customStyle }) => {
  const [selected, setSelected] = useState(0);
  const { label } = goalOptions[selected];
  const Icon = goalOptions[selected].SVGIcon;

  useEffect(() => {
    switch (selected) {
      case 0:
        setItemToLocalStorage('userInfo', {
          ...getItemFromLocalStorage('userInfo'),
          info: {
            ...getItemFromLocalStorage('userInfo')?.info,
            goal: 'Losing Fat',
          },
        });
        break;
      case 1:
        setItemToLocalStorage('userInfo', {
          ...getItemFromLocalStorage('userInfo'),
          info: {
            ...getItemFromLocalStorage('userInfo')?.info,
            goal: 'Build Muscle',
          },
        });
        break;
      case 2:
        setItemToLocalStorage('userInfo', {
          ...getItemFromLocalStorage('userInfo'),
          info: {
            ...getItemFromLocalStorage('userInfo')?.info,
            goal: 'Performance',
          },
        });
        break;
      default:
        break;
    }
  }, [selected]);

  return (
    <div className={`mob goals-dropdown ${style}`} style={customStyle}>
      {/* <div className="dropdown show">
        <a
          className="btn cta-w dropdown-toggle"
          href="#"
          role="button"
          id="dropdownMenuLink"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            className="mg-right-5"
          >
            <g fill="none" fillRule="evenodd">
              <g fill="#FFF">
                <g>
                  <g>
                    <g>
                      <g>
                        <path
                          d="M14.29 9.2c.156.156.244.368.244.589v.422c-.003.22-.09.432-.245.59l-5.713 5.701c-.104.105-.246.165-.395.165-.148 0-.29-.06-.394-.165l-.79-.789c-.104-.102-.163-.243-.163-.389 0-.146.06-.286.164-.389L11.944 10 6.998 5.065c-.105-.104-.165-.246-.165-.395 0-.148.06-.29.165-.394l.789-.778c.104-.105.246-.165.394-.165.149 0 .29.06.395.165L14.29 9.2z"
                          transform="translate(-1220 -605) translate(982 244) translate(0 288) translate(215 50) rotate(90 10.5 33.5)"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
          {label}
          <Icon />
        </a>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
          {goalOptions.map((op, i) => (
            <Goal
              key={`goal-${i}`}
              goal={goalOptions[i]}
              selected={selected}
              onClick={() => setSelected(i)}
            />
          ))}
        </div>
      </div> */}
      <Link
        id="firststart"
        className="cta-primary new-start-button"
        onClick={() => {
          event('Startnow', { type: 'Startfrom1stsec' });
          trackCustom('Quizstart', { type: 'Startfrom1stsec' });
        }}
        to={'/quiz?ref=1stsec'}
      >
        ابدأ التغيير
      </Link>
    </div>
  );
};

const StartJourneyBtn = ({ stage }) => (
  <Link
    to={'/quiz?ref=' + stage + 'sec'}
    onClick={() => {
      event('Startnow', { type: 'Startfrom' + stage + 'sec' });
    }}
    className="cta-primary-icon mg-top-20"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#FFF">
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <path
                      d="M15.947 11.04c.188.187.293.441.293.707v.506c-.003.265-.108.518-.293.707l-6.856 6.843c-.125.126-.295.197-.473.197s-.349-.071-.474-.197l-.947-.947c-.125-.123-.196-.291-.196-.467 0-.176.07-.344.196-.467L13.133 12 7.197 6.078C7.071 5.953 7 5.782 7 5.604c0-.177.071-.348.197-.473l.947-.934C8.27 4.071 8.44 4 8.618 4s.348.071.473.197l6.856 6.843z"
                      transform="translate(-850 -3014) translate(-93 2243) translate(553 144) translate(358 611) translate(32 12) matrix(-1 0 0 1 24 4)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
    ابدأ رحلتك الأن
  </Link>
);

// eslint-disable-next-line react/prop-types
const Hero = ({ onGoalChange }) => (
  <div className="hero introductionSection">
    <div className="container sec-mg-b">
      <div className="row">
        <div className="col-md-5 mockup svg-p">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 969 299"
            className="shape-o st0"
          >
            <g transform="translate(0 -281)">
              <path
                className="st0"
                d="M147.8,580h-72l96.7-299h71.9L147.8,580z M293.7,580h-71.9l96.7-299h71.9L293.7,580z M2.9,580H-69l96.7-299
                                h71.9L2.9,580z M437.9,580h-72.4l97.3-299h72.4L437.9,580z M582.3,580h-71.9l96.7-299H679L582.3,580z"
              />
              <path
                className="st0"
                d="M582.3,580h-71.9l96.7-299H679L582.3,580z M726.6,580h-72.4l97.3-299h72.4L726.6,580z M872,580h-71.9
                                l96.7-299h71.9L872,580z"
              />
            </g>
          </svg>
          <img
            src="https://cdn.elcoach.me/assets/website/images/phone-app.png"
            alt="Elcoach Training App"
            className="phone-mockup animate__animated animate__bounceInLeft"
          />
        </div>
        <div className="col-md-7 contentContainer">
          <div className="content animate__animated animate__bounceInRight contentWidth">
            <p className="head-title custom-mb-30 ">مدربك في جيبك</p>
            <p className="title mg-bottom-20 website-text-View">
              مارس تمارينك الرياضية في المنزل أو الجيم، واحصل على نظام غذائي
              <br /> صحي مناسب لأهدافك، وتابع تقدمك علي يد أفضل المدربين <br />
              مع تطبيق الفتنس رقم #1 في الوطن العربي.
            </p>
            <p className="title mg-bottom-20 mobile-text-View">
              مارس تمارينك الرياضية في المنزل أو الجيم،
              <br /> واحصل على نظام غذائي صحي مناسب لأهدافك،
              <br /> وتابع تقدمك علي يد أفضل المدربين مع تطبيق
              <br /> الفتنس رقم#1 في الوطن العربي.
            </p>
            {/* <p className="title bold mg-bottom-20">هدفي حاليا هو..</p> */}
            <GoalsDropdown onGoalChange={onGoalChange} style={'mg-top-40'} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

const Features = () => (
  <div className="features sec-pd">
    <div className="container">
      <div className="head-content text-center animate__animated animate__fadeInUp">
        <h3 className='how-it-works'>كيف يعمل الكوتش؟</h3>
        <p className="head-title-sec">ابدأ في أقل من دقيقة</p>
        <p className="head-p-sec">
          تطبيق الكوتش أسهل وأسرع طريقة للحصول على برنامجك المثالي الذي أعده
          أفضل المدربين وخبراء التغذية وباستخدام تقنية الذكاء الاصطناعي.
        </p>
      </div>

      <div className="slider">
        <div className="row">
          <div className="col-4 rm-mobile">
            <div
              className="content"
              data-target="#carouselExampleIndicators"
              data-slide-to="2"
            >
              <p className="number">3</p>
              <p className="bold">اختر تفضيلاتك في التغذية</p>
              <p>
                حدد جميع تفضيلاتك كي نحضّر لك أفضل الوصفات الصحية بدون تخمينات.
              </p>
              <hgroup className="circle-load circle-3">
                <svg
                  width="240px"
                  height="240px"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="110"
                    cy="110"
                    r="20"
                    strokeWidth="4"
                    stroke="gainsboro"
                    fill="none"
                  />
                  <circle
                    cx="110"
                    cy="110"
                    r="20"
                    strokeWidth="4"
                    stroke="#faa"
                    fill="none"
                    className="circle-load-svg-3"
                  />
                </svg>
              </hgroup>
            </div>
            <div
              className="content"
              data-target="#carouselExampleIndicators"
              data-slide-to="3"
            >
              <p className="number">4</p>
              <p className="bold">صمم تمارينك بدون قيود</p>
              <p>
                أخبرنا بمكان التمرين، مستواك الرياضي، والأدوات المتاحة كي تتمرن
                بدون قيود
              </p>
              <hgroup className="circle-load circle-4">
                <svg
                  width="240px"
                  height="240px"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="110"
                    cy="110"
                    r="20"
                    strokeWidth="4"
                    stroke="gainsboro"
                    fill="none"
                  />
                  <circle
                    cx="110"
                    cy="110"
                    r="20"
                    strokeWidth="4"
                    stroke="#faa"
                    fill="none"
                    className="circle-load-svg-4"
                  />
                </svg>
              </hgroup>
            </div>
          </div>
          <div className="col-md-4">
            <div className="stripes-bg"></div>
            <div
              id="carouselExampleIndicators"
              className="carousel slide"
              data-ride="carousel"
              data-interval="4700"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    className="d-block w-100"
                    src="https://cdn.elcoach.me/assets/website/images/feature-1.png"
                    alt="First slide"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    className="d-block w-100"
                    src="https://cdn.elcoach.me/assets/website/images/feature-2.png"
                    alt="Second slide"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    className="d-block w-100"
                    src="https://cdn.elcoach.me/assets/website/images/feature-3.png"
                    alt="Third slide"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    className="d-block w-100"
                    src="https://cdn.elcoach.me/assets/website/images/feature-4.png"
                    alt="Forth slide"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-4 rm-mobile">
            <div
              className="content"
              data-target="#carouselExampleIndicators"
              data-slide-to="0"
            >
              <p className="number">1</p>
              <p className="bold">حدد هدفك الذي تود تحقيقه</p>
              <p>
                يصمم الكوتش برنامجك المتكامل بناءًا على هدفك كي تصل إليه أسرع.
              </p>
              <hgroup className="circle-load circle-1">
                <svg
                  width="240px"
                  height="240px"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="110"
                    cy="110"
                    r="20"
                    strokeWidth="4"
                    stroke="gainsboro"
                    fill="none"
                  />
                  <circle
                    cx="110"
                    cy="110"
                    r="20"
                    strokeWidth="4"
                    stroke="#faa"
                    fill="none"
                    className="circle-load-svg-1"
                  />
                </svg>
              </hgroup>
            </div>
            <div
              className="content"
              data-target="#carouselExampleIndicators"
              data-slide-to="1"
            >
              <p className="number">2</p>
              <p className="bold">أخبرنا بمعلومات عن جسمك</p>
              <p>
                أدخل وزنك، طولك، نسبة الدهون بجسمك كي نتعرف على احتياجاتك بدقة.
              </p>
              <hgroup className="circle-load circle-2">
                <svg
                  width="240px"
                  height="240px"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="110"
                    cy="110"
                    r="20"
                    strokeWidth="4"
                    stroke="gainsboro"
                    fill="none"
                  />
                  <circle
                    cx="110"
                    cy="110"
                    r="20"
                    strokeWidth="4"
                    stroke="#faa"
                    fill="none"
                    className="circle-load-svg-2"
                  />
                </svg>
              </hgroup>
            </div>
          </div>
        </div>
      </div>
      <div
        id="carouselExampleSlidesOnly"
        className="carousel slide mobile-view"
        data-ride="carousel"
        data-interval="4700"
      >
        <div className="carousel-inner text-center">
          <div className="carousel-item active">
            <p className="bold">حدد هدفك الذي تود تحقيقه</p>
            <p>
              يصمم الكوتش برنامجك المتكامل بناءًا على هدفك كي تصل إليه أسرع.
            </p>
          </div>
          <div className="carousel-item">
            <p className="bold">أخبرنا بمعلومات عن جسمك</p>
            <p>
              أدخل وزنك، طولك، نسبة الدهون بجسمك كي نتعرف على احتياجاتك بدقة.
            </p>
          </div>
          <div className="carousel-item">
            <p className="bold">اختر تفضيلاتك في التغذية</p>
            <p>
              حدد جميع تفضيلاتك كي نحضّر لك أفضل الوصفات الصحية بدون تخمينات.
            </p>
          </div>
          <div className="carousel-item">
            <p className="bold">صمم تمارينك بدون قيود</p>
            <p>
              أخبرنا بمكان التمرين، مستواك الرياضي، والأدوات المتاحة كي تتمرن
              بدون قيود
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const Levels = () => (
  <div className="levels sec-pd">
    <div className="container">
      <div className="shape-g">
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 319.59 106.38"
          style={{ fill: '#A0E50B' }}
          className="st0"
        >
          <g>
            <path
              className="st0"
              d="M78.18,107H52.59L87,0h25.59L78.18,107z M130.18,107h-25.59L139,0h25.59L130.18,107z M26.18,107H0.59L35,0
                      h25.59L26.18,107z M181.61,107h-26.02L190.57,0h26.02L181.61,107z M233.18,107h-25.59L242,0h25.59L233.18,107z"
            />
            <path
              className="st0"
              d="M233.18,107h-25.59L242,0h25.59L233.18,107z M284.61,107h-26.02L293.57,0h26.02L284.61,107z"
            />
          </g>
        </svg>
      </div>
      <div className="shape-b">
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 969 299"
          style={{ fill: '#606060' }}
        >
          <g>
            <g transform="translate(0 -281)">
              <g>
                <path
                  className="st0"
                  d="M147.8,580h-72l96.7-299h71.9L147.8,580z M293.7,580h-71.9l96.7-299h71.9L293.7,580z M2.9,580H-69l96.7-299
                          h71.9L2.9,580z M437.9,580h-72.4l97.3-299h72.4L437.9,580z M582.3,580h-71.9l96.7-299H679L582.3,580z"
                />
                <path
                  className="st0"
                  d="M582.3,580h-71.9l96.7-299H679L582.3,580z M726.6,580h-72.4l97.3-299h72.4L726.6,580z M872,580h-71.9
                          l96.7-299h71.9L872,580z"
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div className="row items-center">
        <div className="col-md-6">
          <div className="content " id="content-x">
            <h3 className='why-elcoach'>لماذا تجربة الكوتش مختلفة كليًا؟</h3>
            <p className="head-title-sec">تمارين لكل المستويات</p>
            <p className="head-p-sec">
              اتمرن من أي مكان، وفي أي وقت، سواء كنت مبتدئاً أو محترفاً، بمعدات
              أو بدون معدات، تحت إشراف مدربين محترفين.
            </p>
            <div className="note rm-mobile">
              <img loading="lazy" src="./assets/images/icons/user.svg" alt="" />
              <div className="data">
                <p className="bold">برامج مصممة خصيصًا لك</p>
                <p className="head-p-sec">
                  مهما كان هدفك أو مستواك الرياضي، هنالك برنامج مصمم خصيصًا لك
                  يمكنك ممارسته بالأيام التي تفضلها والأدوات المتاحة.
                </p>
              </div>
            </div>
            <div className="note rm-mobile">
              <img loading="lazy" src="./assets/images/icons/down.svg" alt="" />
              <div className="data">
                <p className="bold">بدون إنترنت</p>
                <p className="head-p-sec">
                  شاهد جميع التمارين بالفيديو وبالإرشاد الصوتي، وبدون الحاجة إلى
                  الاتصال بالإنترنت.
                </p>
              </div>
            </div>
            <div className="note rm-mobile">
              <img
                loading="lazy"
                src="./assets/images/icons/power.svg"
                alt=""
              />
              <div className="data">
                <p className="bold">الأذكى والأكثر فعالية</p>
                <p className="head-p-sec">
                  يتكيف برنامجك بشكل ذكي مع تقدمك من شهر للتالي لدفعك بشكل أسرع
                  وأكثر فعالية نحو رفع مستوى لياقتك وتحقيق أهدافك.
                </p>
              </div>
            </div>
            {useMediaQuery({ query: '(max-width: 900px)' }) ? null : (
              <StartJourneyBtn stage={'2nd'} />
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="mockup animate__animated animate__fadeInRight">
            <img
              src="https://cdn.elcoach.me/assets/website/images/level-1.png"
              alt=""
            />
          </div>
        </div>
        <div className="col-md-6 mobile-view">
          <div className="note">
            <img loading="lazy" src="./assets/images/icons/user.svg" alt="" />
            <div className="data">
              <p className="bold">برامج مصممة خصيصًا لك</p>
              <p className="head-p-sec">
                مهما كان هدفك أو مستواك الرياضي، هنالك برنامج مصمم خصيصًا لك
                يمكنك ممارسته بالأيام التي تفضلها والأدوات المتاحة.
              </p>
            </div>
          </div>
          <div className="note">
            <img loading="lazy" src="./assets/images/icons/down.svg" alt="" />
            <div className="data">
              <p className="bold">استخدام بدون إنترنت</p>
              <p className="head-p-sec">
                شاهد جميع التمارين بالفيديو وبالإرشاد الصوتي، وبدون الحاجة إلى
                الاتصال بالإنترنت.
              </p>
            </div>
          </div>
          <div className="note">
            <img loading="lazy" src="./assets/images/icons/power.svg" alt="" />
            <div className="data">
              <p className="bold">الأذكى والأكثر فعالية</p>
              <p className="head-p-sec">
                يتكيف برنامجك بشكل ذكي مع تقدمك من شهر للتالي لدفعك بشكل أسرع
                وأكثر فعالية نحو رفع مستوى لياقتك وتحقيق أهدافك.
              </p>
            </div>
          </div>
          {useMediaQuery({ query: '(max-width: 900px)' }) && (
            <StartJourneyBtn stage={'2nd'} />
          )}
        </div>
      </div>
    </div>
  </div>
);

const Nutrition = () => (
  <div className="nutrition sec-pd">
    <div className="container">
      <div className="shape-o">
        <svg
          className="st0"
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 969 299"
          style={{ fill: '#606060' }}
        >
          <g>
            <g transform="translate(0 -281)">
              <g>
                <path
                  className="st0"
                  d="M147.8,580h-72l96.7-299h71.9L147.8,580z M293.7,580h-71.9l96.7-299h71.9L293.7,580z M2.9,580H-69l96.7-299
                          h71.9L2.9,580z M437.9,580h-72.4l97.3-299h72.4L437.9,580z M582.3,580h-71.9l96.7-299H679L582.3,580z"
                />
                <path
                  className="st0"
                  d="M582.3,580h-71.9l96.7-299H679L582.3,580z M726.6,580h-72.4l97.3-299h72.4L726.6,580z M872,580h-71.9
                          l96.7-299h71.9L872,580z"
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div className="row items-center">
        <div className={'col-md-6 item-order-2 mob'}>
          <div className="mockup animate__animated animate__fadeInLeft rm-mobile">
            <img
              src="https://cdn.elcoach.me/assets/website/images/level-2.png"
              alt=""
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="content animate__animated animate__fadeInRight">
            <h3 className='why-elcoach'>لماذا تجربة الكوتش مختلفة كليًا؟</h3>
            <p className="head-title-sec">أفضل نظام غذائي صحي</p>
            <p className="head-p-sec">
              احصل كل يوم على وصفات صحية، شهية، سهلة التحضير. بدل الوجبات
              ومكوناتها بأخرى حسب احتياجات جسمك من السعرات وتفضيلاتك الشخصية.
            </p>
            <div className="note rm-mobile">
              <img
                loading="lazy"
                src="./assets/images/icons/download.svg"
                alt=""
              />
              <div className="data">
                <p className="bold">أنظمة غذائية متنوعة</p>
                <p className="head-p-sec">
                  نباتي، فيجان، صيام متقطع، وجبات اقتصادية أو جورميه، وخيارات
                  أخرى لا حصر لها، اختر منها ما يناسب احتياجاتك وتفضيلاتك.
                </p>
              </div>
            </div>
            <div className="note rm-mobile">
              <img
                loading="lazy"
                src="./assets/images/icons/nutri.svg"
                alt=""
              />
              <div className="data">
                <p className="bold">تغيير الوجبات بضغطة</p>
                <p className="head-p-sec">
                  بدل أي وجبة، أو أي من مكوناتها بضغطة واحدة، واحصل على طريقة
                  تحضير سهلة ومفصلة.
                </p>
              </div>
            </div>
            <div className="note rm-mobile">
              <img
                loading="lazy"
                src="./assets/images/icons/nutlist.svg"
                alt=""
              />
              <div className="data">
                <p className="bold">قائمة بالطلبات</p>
                <p className="head-p-sec">
                  جهزنا لك قائمة أسبوعية بالطلبات توفيرًا للوقت والمجهود.
                </p>
              </div>
            </div>
            <div className={'col-md-6 item-order-2 mob'}>
              <div className="mockup animate__animated animate__fadeInLeft mobile-view">
                <img
                  src="https://cdn.elcoach.me/assets/website/images/level-2.png"
                  alt=""
                />
              </div>
            </div>
            <div className="note mobile-view">
              <img
                loading="lazy"
                src="./assets/images/icons/download.svg"
                alt=""
              />
              <div className="data">
                <p className="bold">أنظمة غذائية متنوعة</p>
                <p className="head-p-sec">
                  نباتي، فيجان، صيام متقطع، وجبات اقتصادية أو جورميه، وخيارات
                  أخرى لا حصر لها، اختر منها ما يناسب احتياجاتك وتفضيلاتك.
                </p>
              </div>
            </div>
            <div className="note mobile-view">
              <img
                loading="lazy"
                src="./assets/images/icons/nutri.svg"
                alt=""
              />
              <div className="data">
                <p className="bold">تغيير الوجبات بضغطة</p>
                <p className="head-p-sec">
                  بدل أي وجبة، أو أي من مكوناتها بضغطة واحدة، واحصل على طريقة
                  تحضير سهلة ومفصلة.
                </p>
              </div>
            </div>
            <div className="note mobile-view">
              <img
                loading="lazy"
                src="./assets/images/icons/nutlist.svg"
                alt=""
              />
              <div className="data">
                <p className="bold">قائمة بالطلبات</p>
                <p className="head-p-sec">
                  جهزنا لك قائمة أسبوعية بالطلبات توفيرًا للوقت والمجهود.
                </p>
              </div>
            </div>
            <StartJourneyBtn stage={'3rd'} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

const Benefits = () => (
  <div className="benefits">
    <div className="container">
      <div className="row items-center">
        <div className="col-md-4 animate__animated animate__slideInUp">
          <div className="bg-b">
            <div className="content">
              <p className="head-title-sec">تغير في أقل من ٣٠ يوم</p>
              <p className="head-p-sec">
                ٩ من كل ١٠ مستخدمين لتطبيق الكوتش حصلوا على تغير ملحوظ في أقل من
                ٣٠ يوم.
              </p>
            </div>
            <img
              src="https://cdn.elcoach.me/assets/website/images/analytics.png"
              alt=""
            />
          </div>
        </div>
        <div className="col-md-4 animate__animated animate__slideInUp">
          <div className="bg-purp">
            <div className="content">
              <p className="head-title-sec">تابع تقدمك</p>
              <p className="head-p-sec">
                بروفايلك الشخصي يتم تحديثه باستمرار بإحصائيات لمتابعة تقدمك خطوة
                بخطوة، وتحفيزك للمواظبة على برنامجك.
              </p>
            </div>
            <img
              src="https://cdn.elcoach.me/assets/website/images/progress.png"
              alt=""
            />
          </div>
        </div>
        <div className="col-md-4 animate__animated animate__slideInUp">
          <div className="bg-p">
            <div className="content">
              <p className="head-title-sec">نافس أصدقائك</p>
              <p className="head-p-sec">
                تحدى نفسك وأصدقائك. جمع نقط وسترايكس مع كل تمرينة ووجبة وكوب ماء
                وانجاز. اوصل لمستويات أعلى وابنِ عادات صحية بشكل أسرع.
              </p>
            </div>
            <img
              src="https://cdn.elcoach.me/assets/website/images/new-level.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

const Support = () => (
  <div className="support sec-pd">
    <div className="container">
      <div className="shape-p">
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 969 299"
          style={{ fill: '#606060' }}
          className="st0"
        >
          <g>
            <g transform="translate(0 -281)">
              <g>
                <path
                  className="st0"
                  d="M147.8,580h-72l96.7-299h71.9L147.8,580z M293.7,580h-71.9l96.7-299h71.9L293.7,580z M2.9,580H-69l96.7-299
                          h71.9L2.9,580z M437.9,580h-72.4l97.3-299h72.4L437.9,580z M582.3,580h-71.9l96.7-299H679L582.3,580z"
                />
                <path
                  className="st0"
                  d="M582.3,580h-71.9l96.7-299H679L582.3,580z M726.6,580h-72.4l97.3-299h72.4L726.6,580z M872,580h-71.9
                          l96.7-299h71.9L872,580z"
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div className="row item-center">
        <div className="col-md-7">
          <div className="content animate__animated animate__fadeInLeft">
            <h3 className='why-elcoach'>لماذا تجربة الكوتش مختلفة كليًا؟</h3>
            <p className="head-title-sec">متابعة فردية على مدار اليوم</p>
            <p className="head-p-sec">
              استمتع بمتابعة مباشرة مع مدربين محترفين وخبراء تغذية لإرشادك،
              وإجابة جميع أسئلتك، وتشجيعك باستمرار.
            </p>
            <div className="note rm-mobile">
              <img
                loading="lazy"
                src="./assets/images/icons/chat-icon.svg"
                alt=""
              />
              <div className="data">
                <p className="bold">تحدث مع الكوتش</p>
                <p className="head-p-sec">
                  فريق على أعلى مستوى من المدربين ومتخصصي التغذية جاهزين في أى
                  وقت لدعمك وإجابة كل استفساراتك.
                </p>
              </div>
            </div>
            <div className="note rm-mobile">
              <img
                loading="lazy"
                src="./assets/images/icons/notifi.svg"
                alt=""
              />
              <div className="data">
                <p className="bold">اشعارات وتنبيهات</p>
                <p className="head-p-sec">
                  لتذكيرك بشرب الماء، مواعيد التمرين، ووجباتك القادمة.
                </p>
              </div>
            </div>
            {/* <div className="note rm-mobile">
              <img loading="lazy" src="./assets/images/icons/users-group.svg" alt="" />
              <div className="data">
                <p className="bold">عيش روح المجموعة</p>
                <p className="head-p-sec">
                  انضم إلى جروب مغلق للمتابعة على فيسبوك به آلاف المتدربين
                  للترحيب بك، تشجيعك على المواظبة، وإجابة كل استفساراتك.
                </p>
              </div>
            </div> */}
            {useMediaQuery({ query: '(max-width: 900px)' }) ? null : (
              <StartJourneyBtn stage={'4th'} />
            )}
          </div>
        </div>
        <div className="col-md-5">
          <div className="mockup animate__animated animate__fadeInRight">
            <img
              src="https://cdn.elcoach.me/assets/website/images/level-3.png"
              alt=""
            />
          </div>
        </div>
        <div className="col-md-6 mobile-view">
          <div className="note">
            <img
              loading="lazy"
              src="./assets/images/icons/chat-icon.svg"
              alt=""
            />
            <div className="data">
              <p className="bold">تحدث مع الكوتش</p>
              <p className="head-p-sec">
                فريق على أعلى مستوى من المدربين ومتخصصي التغذية جاهزين في أى وقت
                لدعمك وإجابة كل استفساراتك.
              </p>
            </div>
          </div>
          <div className="note">
            <img loading="lazy" src="./assets/images/icons/notifi.svg" alt="" />
            <div className="data">
              <p className="bold">اشعارات وتنبيهات</p>
              <p className="head-p-sec">
                لتذكيرك بشرب الماء، مواعيد التمرين، ووجباتك القادمة.
              </p>
            </div>
          </div>
          {/* <div className="note">
              <img loading="lazy" src="./assets/images/icons/users-group.svg" alt="" />
              <div className="data">
                <p className="bold">عيش روح المجموعة</p>
                <p className="head-p-sec">
                  انضم إلى جروب مغلق للمتابعة على فيسبوك به آلاف المتدربين
                  للترحيب بك، تشجيعك على المواظبة، وإجابة كل استفساراتك.
                </p>
              </div>
            </div> */}
          {useMediaQuery({ query: '(max-width: 900px)' }) && (
            <StartJourneyBtn stage={'4th'} />
          )}
        </div>
      </div>
    </div>
  </div>
);
//
const InstallApp = () => {
  return (
    <div className="download-app sec-pd mg-top-20">
      <div className="container">
        <div className="row">
          <div className={'col-md-6 item-order-2 mob'}>
            <div className="team">
              <img
                src="https://cdn.elcoach.me/assets/website/images/coach-team.png"
                alt=""
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="content">
              <p className="head-title-sec">اكسب صحتك</p>
              <p>
                حمل تطبيق الكوتش الآن وخذ أول خطوة في رحلة تغيير حياتك للأفضل
              </p>
              <a
                href="https://apps.apple.com/app/ap
              ple-store/id1431776088"
                target="_blank"
                onClick={() => {
                  trackCustom('WebInstall', { type: 'ios' });
                }}
              >
                <img
                  src="https://cdn.elcoach.me/assets/website/images/app-store.png"
                  alt=""
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.elcoach.me"
                target="_blank"
                onClick={() => {
                  trackCustom('WebInstall', { type: 'android' });
                }}
              >
                <img
                  src="https://cdn.elcoach.me/assets/website/images/google-play.png"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const JoinUs = () => (
  <div className="stories stories-home sec-pd">
    <div className="container">
      <div className="content text-center">
        <p className="head-title-sec">انضم لأكثر من نصف مليون مستخدم</p>
        <p className="head-p-sec">
          أسهل وأسرع طريقة للحصول على برنامجك المثالي الذي أعده أفضل المدربين
          وخبراء التغذية وباستخدام تقنية الذكاء الاصطناعي.
        </p>
      </div>
      <AfterAndBefore home={true} />
    </div>
  </div>
);

const Articles = () => (
  <div className="articles sec-pd">
    <div className="container">
      <div className="head-content text-center">
        <p className="head-title-sec">
          تعلّم المزيد عن الرياضة والتغذية الصحية <br />
        </p>
      </div>
      <div className="row">
        <div className="col-md-4">
          <a href="https://blog.elcoach.me/articles/عسل-السدر/" target="_blank">
            <div className="article-box">
              <img
                src="https://blog.elcoach.me/articles/wp-content/uploads/2020/12/عسل-السدر.jpeg"
                alt=""
              />
              <div>
                <p className="category">تغذية</p>
                <p className="time">
                  {' '}
                  - <span id="reading-time">5</span> دقائق قراءة
                </p>
              </div>
              <div className="content">
                <p className="title">
                  4 فوائد صحية وعلاجية أساسية في عسل السدر
                </p>
                <p className="sub-title">
                  لطالما تم وصف العسل العضوي واستخدامه من قبل العديد من الدول
                  كعامل علاجي وشراب صحي، وأكثر بكثير من مجرد مكون غذائي تضعه على
                  الخبز المحمص أو الحبوب...
                </p>
              </div>
            </div>
          </a>
        </div>
        <div className="col-md-4">
          <a
            href="https://blog.elcoach.me/articles/نسبة-الكافيين-في-المشروبات/"
            target="_blank"
          >
            <div className="article-box">
              <img
                src="https://blog.elcoach.me/articles/wp-content/uploads/2021/01/نسبة-الكافيين-في-المشروبات-المختلفة.jpg"
                alt=""
              />
              <div>
                <p className="category">تغذية</p>
                <p className="time">
                  {' '}
                  - <span id="reading-time">7</span> دقائق قراءة
                </p>
              </div>
              <div className="content">
                <p className="title">
                  نسبة الكافيين في المشروبات وكيف تختار الأفضل
                </p>
                <p className="sub-title">
                  إنه موجود في أكثر من 60 نوعًا من النباتات ويتم الاستمتاع به في
                  جميع أنحاء العالم، وخاصة في القهوة والشوكولاتة والشاي. تختلف
                  نسبة الكافيين في المشروبات باختلاف المكونات وكيفية تحضير
                  المشروب. وبينما يعتبر الكافيين مساعدا في حرق الدهون...
                </p>
              </div>
            </div>
          </a>
        </div>
        <div className="col-md-4">
          <a href="https://blog.elcoach.me/articles/كروس-فيت/" target="_blank">
            <div className="article-box">
              <img
                src="https://blog.elcoach.me/articles/wp-content/uploads/2020/12/كروس-فيت.jpg"
                alt=""
              />
              <div>
                <p className="category">تمارين</p>
                <p className="time">
                  {' '}
                  - <span id="reading-time">10</span> دقائق قراءة
                </p>
              </div>
              <div className="content">
                <p className="title">
                  دليلك الكامل لفهم تدريبات كروس فيت وأفضل تمارينها
                </p>
                <p className="sub-title">
                  في السنوات العشر الماضية، تحولت كروس فيت إلى ظاهرة تدريبية.
                  وتنتشر الآن صالات رياضية مخصصة للكروس فيت في جميع أنحاء العالم
                  تقريبا، لذا أصبح العثور على مكان للتمرين أسهل من أي وقت مضى
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div className="text-center mg-top-40">
        <a
          href="https://blog.elcoach.me/articles/"
          target="_blank"
          className="cta-primary"
        >
          اقرأ المزيد من المقالات
        </a>
      </div>
    </div>
  </div>
);

const Goals = ({ onGoalChange }) => (
  <div className="goals sec-pd mg-top-20">
    <div className="shape-g">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 319.59 106.38"
        style={{ fill: '#A0E50B' }}
        className="st0"
      >
        <g>
          <path
            className="st0"
            d="M78.18,107H52.59L87,0h25.59L78.18,107z M130.18,107h-25.59L139,0h25.59L130.18,107z M26.18,107H0.59L35,0
                    h25.59L26.18,107z M181.61,107h-26.02L190.57,0h26.02L181.61,107z M233.18,107h-25.59L242,0h25.59L233.18,107z"
          />
          <path
            className="st0"
            d="M233.18,107h-25.59L242,0h25.59L233.18,107z M284.61,107h-26.02L293.57,0h26.02L284.61,107z"
          />
        </g>
      </svg>
    </div>

    <div className="container custom-container">
      <div className="row">
        <div className="col-md-4 item-order-2   ">
          <GoalsDropdown
            onGoalChange={onGoalChange}
            customStyle={{ justifyContent: 'center', marginTop: '5.5%' }}
          />
        </div>
        <div className="col-md-8 ">
          <div className="content">
            <p className="head-title-sec">ما هو هدفك هذا العام؟</p>
            <p>
              مهما كان هدفك أو مستواك، الكوتش سيساعدك على تحقيق أهدافك بشكل أسهل
              وأسرع، ابدأ اليوم مع الكوتش
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default function Home({ plan, offerId, identity, partner }) {
  const [goal, setGoal] = useState('');

  // useEffect(() => {
  //   if (offerId && identity && plan) {
  //     deleteFromLocalStorage('partner');
  //     setItemToLocalStorage('partnerOffer', {
  //       plan: plan,
  //       offer: offerId,
  //       partner: identity,
  //     });
  //   }
  //   // if (!partner && !identity) {
  //   //   deleteFromLocalStorage('partner');
  //   //   deleteFromLocalStorage('partnerOffer');
  //   // }
  // }, []);

  return (
    <>
      <Hero onGoalChange={() => setGoal('') /* TODO */} />
      <Features />
      <Levels />
      <Nutrition />
      <Support />
      <Benefits />
      <InstallApp />
      <JoinUs />
      <Articles />
      <Goals onGoalChange={() => setGoal('') /* TODO */} />
    </>
  );
}

Home.propTypes = {};
