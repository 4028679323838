import React from 'react';

const StartChangeSticky = () => (
  <div id="startchangesticky" className="startsticky-hide" dangerouslySetInnerHTML={{__html:`
  <div style="box-shadow: 0px -5px 13px 2px #61616170;/* align-items: baseline; */background: #fff;color: white;display: flex;flex-wrap: wrap;/* justify-content: space-between; */left: 0px;position: fixed;width: 100%;z-index: 998;bottom: 0px;/* padding: 41px; */height: 75px;align-items: center;text-align: center;padding: 0 50px;">
<a href="/quiz?ref=stickybottom" class="cta-primary" style="
    margin-bottom: unset;
    height: 50px;
    /* width: 270px; */
    line-height: 1.1;
">ابدأ التغيير</a>
</div>
  `}}>

  </div>
 
);

export default StartChangeSticky;
